<template>
  <div id="clue-list" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div class="search-box flex-a-center flex-between">
        <div class="flex-a-center">
          <span class="font">{{ $t("search") }}</span
          >：
          <!-- <zc-search
            open_path="{$webPath}"
            :address_list="address"
            title_type="user"
            title="clue_list"
            :search_data="searchForm"
            callback="getData"
            :key="searchKey"
            :must_search="mustSearch"
          ></zc-search> -->
          <div class="" :style="{ width: tableWidth }">
            <el-input
              v-model="key"
              class="search-input ml10"
              :placeholder="$t('clueList.text')"
              @keyup.enter.native="getList(0)"
            ></el-input>
            <el-button class="search-button-1" @click="getList(0)">{{
              $t("search")
            }}</el-button>
            <el-button class="search-button-2" @click="reset">{{
              $t("reset")
            }}</el-button>
          </div>
        </div>
      </div>
      <div class="top_box" :style="{ width: tableWidth }">
        <div class="flex-a-center">
          <h1 class="tab-tit">
            {{ $t("clueList.list") }}
          </h1>
          <div class="status-box">
            <span
              v-for="(sItem, sIndex) in statusOp"
              :class="{ 'active-tab': activeIndex == sIndex }"
              @click="changeStatus(sItem.val, sIndex)"
              :key="sIndex"
            >
              {{ sItem.name }}
            </span>
          </div>
        </div>
        <div class="flex">
          <el-button @click="add_clue" type="text" size="mini" class="add-btn">
            <i class="iconfont icon-xinzengxiansuo"></i>
            {{ $t("clueList.new") }}
          </el-button>
          <el-popover
            placement="right"
            width="700"
            ref="popover"
            trigger="click"
          >
            <div class="handel-popover">
              <div class="popover-left">
                <div class="popover-left-top">
                  {{ $t("clueList.allRecord") }}
                </div>
                <el-checkbox-group
                  v-model="checkListField"
                  @change="changeCheck"
                  class="popover-checkbox-group"
                >
                  <el-checkbox
                    v-for="(item, index) in allShowColumn"
                    :label="index"
                    :key="index"
                    >{{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
                <div class="popover-right-button"></div>
              </div>
              <div class="popover-right">
                <div class="popover-left-top">
                  {{ $t("clueList.selectedRecord") }}
                </div>
                <div class="popover-checkbox-group">
                  <div
                    v-for="(item, index) in allDisColumn"
                    class="has-item"
                    :key="index"
                  >
                    <span>{{ item.label }}</span>
                    <i
                      class="el-icon-delete red-text hand"
                      @click="delField(index)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="popover-handel-box flex-center">
              <zc-icon-btn
                :content="$t('cancel')"
                btn_type="success"
                btn_class="search_button popover-left-button cancel-btn"
                icon="icon-quxiao"
                @click="cancelField"
              ></zc-icon-btn>
              <zc-icon-btn
                :content="$t('confirm')"
                btn_type="warning"
                btn_class="search_button"
                icon="icon-queren"
                @click="confirmField"
              ></zc-icon-btn>
            </div>
            <div slot="reference" @click="setUp" class="yellow-text ml50 hand">
              <i class="iconfont icon-shezhi"></i>
              <span>{{ $t("contactList.customColumn") }}</span>
            </div>
          </el-popover>
        </div>
      </div>
      <div :style="{ width: tableWidth }">
        <el-table :data="clue_list" @selection-change="clue_selection_change">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column :label="$t('taskManagement.handel')" width="100">
            <template slot-scope="scope">
              <el-popover
                placement="right-start"
                width="158"
                @show="show_operation(scope.row)"
                trigger="click"
              >
                <div class="operation_inner">
                  <div
                    v-if="!scope.row.is_delete"
                    class="operation_item"
                    @click="view_details(current_operate_clue_id)"
                  >
                    {{ $t("clueList.view") }}
                  </div>
                  <div
                    v-if="!scope.row.is_delete && scope.row.clue_status > 0"
                    class="operation_item"
                    @click="edit"
                  >
                    {{ $t("edit") }}
                  </div>
                  <div
                    v-if="!scope.row.is_delete && scope.row.clue_status > 0"
                    class="operation_item"
                    @click="
                      assign(current_operate_clue_id, scope.row.charge_user_ids)
                    "
                  >
                    {{ $t("taskInfo.assign") }}
                  </div>
                  <el-popover
                    v-if="!scope.row.is_delete && scope.row.clue_status > 0"
                    placement="right-end"
                    width="158"
                    trigger="hover"
                  >
                    <div class="operation_inner">
                      <div
                        class="operation_item"
                        v-for="(plan_item, plan_index) in plan_type_options"
                        @click="
                          plan_index != plan_type_options.length - 1
                            ? create_plan(plan_item)
                            : create_task()
                        "
                        :key="plan_index"
                      >
                        <i
                          :class="['iconfont', plan_item.icon, 'task-icon']"
                        ></i>
                        <span class="ml8">{{ plan_item.name }}</span>
                      </div>
                    </div>
                    <div class="operation_item space_between" slot="reference">
                      <span>{{ $t("clueList.plan") }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </el-popover>
                  <el-popover
                    v-if="!scope.row.is_delete && scope.row.clue_status > 0"
                    placement="right-start"
                    width="158"
                    trigger="hover"
                  >
                    <div class="operation_inner">
                      <div
                        v-for="(item, itemIndex) in cusStatusGroupOptions"
                        @click="changeClueStatus(item.id)"
                        class="status_item"
                        :key="itemIndex"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                    <div class="operation_item space_between" slot="reference">
                      <span>{{ $t("clueList.changeStatus") }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </el-popover>
                  <div
                    v-if="!scope.row.is_delete && scope.row.clue_status > 0"
                    class="operation_item"
                    @click="conversion"
                  >
                    {{ $t("clueList.transform") }}
                  </div>
                  <div
                    v-if="!scope.row.is_delete && scope.row.clue_status > 0"
                    class="operation_item"
                    @click="give_up"
                  >
                    {{ $t("clueList.giveUp") }}
                  </div>
                  <div
                    v-if="!scope.row.is_delete"
                    class="operation_item"
                    @click="del"
                  >
                    {{ $t("delete") }}
                  </div>
                  <div
                    v-if="scope.row.is_delete"
                    class="operation_item"
                    @click="view_del_details(scope.row.id)"
                  >
                    {{ $t("clueList.view") }}
                  </div>
                  <div
                    v-if="scope.row.is_delete"
                    class="operation_item"
                    @click="recovery"
                  >
                    {{ $t("clueList.restore") }}
                  </div>
                  <div
                    v-if="
                      scope.row.clue_status == -1 && scope.row.is_delete == 0
                    "
                    class="operation_item"
                    @click="activation(scope.row.id)"
                  >
                    {{ $t("clueList.active") }}
                  </div>
                </div>
                <el-button slot="reference" size="mini">
                  <i class="iconfont icon-qita"></i>
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in col"
            class-name="can-move"
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :width="widthObj[item.prop]"
            :key="index"
          >
            <template slot-scope="scope">
              <template v-if="dropCol[index].prop == 'clue_name'">
                <el-tag
                  v-if="!scope.row.is_delete"
                  :type="
                    [0, -1].indexOf(scope.row.clue_status) >= 0 ? 'success' : ''
                  "
                  @click="view_details(scope.row.id)"
                  >{{ scope.row.clue_name }}
                </el-tag>
                <span
                  class="delete_color gray-text hand"
                  v-else
                  @click="view_del_details(scope.row.id)"
                  >{{ scope.row.clue_name }}</span
                >
              </template>
              <template v-else-if="dropCol[index].prop == 'clue_status'">
                <div
                  :class="[
                    scope.row.clue_status === 0
                      ? 'green-status'
                      : scope.row.clue_status === -1
                      ? 'red-status'
                      : 'blue-status',
                    'clue-status_box',
                  ]"
                >
                  <!-- 进行中 -->
                  <template v-if="[0, -1].indexOf(scope.row.clue_status) < 0">
                    <span
                      class="has-color"
                      v-for="(sItem, sIndex) in getStatusIndex(
                        scope.row.clue_status
                      )"
                      :key="sIndex"
                    ></span>
                    <span
                      class="no-color"
                      v-for="noItem in Object.values(status_group).length +
                      1 -
                      getStatusIndex(scope.row.clue_status)"
                      :key="noItem"
                    ></span>
                  </template>
                  <!-- 已转化的和放弃的  -->
                  <span
                    v-for="i in Object.values(status_group).length + 1"
                    :key="i"
                    v-else
                  ></span>
                </div>
              </template>
              <template v-else-if="dropCol[index].prop == 'recent_plan'">
                <div
                  v-if="scope.row.recent_plan.plan_desc"
                  class="plan_item_box ellipsis"
                >
                  <i
                    :class="[
                      getIconByType(scope.row.recent_plan.plan_type),
                      'mr5',
                      'iconfont',
                    ]"
                  ></i>
                  {{ scope.row.recent_plan.want_create_time }}
                  {{ scope.row.recent_plan.plan_desc }}
                </div>
                <div v-else>
                  {{ $t('projectInfo.no') }}
                </div>
              </template>
              <template v-else>
                {{ scope.row[dropCol[index].prop] }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--   新建计划对话框     -->
      <el-dialog
        :title="$t('clueList.newPlan')"
        :visible.sync="is_create_plan"
        width="650px"
        center
        :before-close="close_create_plan"
      >
        <div class="plan_info_box">
          <div class="plan_info_item flex">
            <span class="tit">
              <span class="red">*</span>
              {{ $t('clueList.planType') }}
            </span>
            <el-select
              v-model="plan_type"
              :placeholder="$t('clueList.text1')"
              size="mini"
              class="w220"
            >
              <el-option
                v-for="(item, index) in plan_type_options.filter(
                  (item) => item.type != 4
                )"
                :key="index"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <div class="plan_info_item flex">
            <span class="tit">
              <span class="red">*</span>
              {{ $t('contactList.contact') }}
            </span>
            <el-select
              size="mini"
              v-model="contact_id"
              :placeholder="$t('clueList.text2')"
              class="w220"
            >
              <el-option
                v-for="(item, index) in contact_options"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="plan_info_item flex">
            <span class="tit">
              <span class="red">*</span>
              {{ $t('emailList.time') }}
            </span>
            <el-date-picker
              class="w220"
              v-model="want_create_time"
              type="datetime"
              size="mini"
              value-format="timestamp"
              :placeholder="$t('clueList.text3')"
            >
            </el-date-picker>
          </div>
          <div class="plan_info_item flex">
            <span class="tit">{{ $t('clueList.text4') }}</span>
            <el-input
              class="w500"
              type="textarea"
              size="'mini"
              :placeholder="$t('clueList.text5')"
              v-model="plan_desc"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="close_create_plan" size="mini"
            >{{ $t('cancel') }}</el-button
          >
          <el-button type="primary" @click="addPlan" size="mini"
            >{{ $t('confirm') }}</el-button
          >
        </span>
      </el-dialog>

      <!--   批量更改状态对话框     -->
      <el-dialog
        :title="$t('clueList.text6')"
        :visible.sync="is_change_status"
        width="300px"
        class="change-status-dia"
        center
      >
        <el-select
          size="mini"
          v-model="clue_status"
          :placeholder="$t('clueList.changeStatus')"
        >
          <el-option
            v-for="(item, index) in cusStatusGroupOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="is_change_status = false" size="mini"
            >{{ $t('cancel') }}</el-button
          >
          <el-button type="primary" @click="batchChangeClueStatus" size="mini"
            >{{ $t('confirm') }}</el-button
          >
        </span>
      </el-dialog>

      <!-- 添加指定人对话框 -->
      <el-dialog
        :title="userDialogTit"
        :visible.sync="is_change_assign"
        center
        v-move
        width="840"
      >
        <div class="user-dialog">
          <template>
            <div
              v-for="(personItem, personIndex) in chargeUserOptions"
              :key="personIndex"
            >
              <el-checkbox
                :indeterminate="isIndeterminateArr[personIndex]"
                v-model="checkAllArr[personIndex]"
                @change="handleCheckAllChange($event, personIndex)"
                class="department-tit"
              >
                <h2 class="group_name">
                  {{ personItem.label }}
                  <span class="user-num">
                    ({{ personItem.children.length }})
                  </span>
                </h2>
              </el-checkbox>

              <el-checkbox-group
                v-model="checkedUserArr[personIndex]"
                class="mb20"
                @change="handleCheckedChange($event, personIndex)"
              >
                <el-checkbox
                  v-for="(mItem, index) in personItem.children"
                  :label="mItem.value"
                  :key="index"
                  >{{ mItem.label }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </template>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="is_change_assign = false" size="mini"
            >{{ $t('cancel') }}</el-button
          >
          <el-button type="primary" size="mini" @click="addClueCharge"
            >{{ $t('confirm') }}</el-button
          >
        </span>
      </el-dialog>

      <!--   批量操作选择框     -->
      <div class="flex handel-bottom">
        <div class="handel-inner_box">
          <div>
            <span class="select-span mr24">
              {{ $t('taskManagement.selected') }}
              {{ selection_num + "/" + total }}
            </span>
            <el-select
              size="mini"
              v-model="batch_operation_type"
              :placeholder="$t('clueList.text7')"
            >
              <el-option
                v-for="(item, index) in batch_operation_options.filter(
                  (item) => item.type_arr.indexOf(type) != -1
                )"
                :key="index"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="application" size="mini">
              {{ $t('taskManagement.apply') }}
            </el-button>
          </div>
          <el-pagination
            @size-change="getList(1, searchData)"
            @current-change="getList(1, searchData)"
            :current-page.sync="page"
            :page-sizes="[20, 40, 60, 80, 100]"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total.sync="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZcIconBtn from "@/components/ZcIconBtn.vue";
import Sortable from "sortablejs";
import "@/plugins/move";
import { savePageOption } from "@/utils/common";
import { toMessage } from "@/utils/message";
export default {
  components: {
    ZcIconBtn,
  },
  name: "ClueList",
  data() {
    return {
      key: "",
      cacheAddr: {},
      searchData: {},
      address: {},
      searchForm: [
        "线索名称",
        "客户区域",
        "公司邮箱",
        "公司名称",
        "创建者",
        "负责人",
        "联系人名称",
        "联系人电话",
        "联系人邮件",
        "联系人称谓",
        "联系人地址",
        "创建时间",
        "计划情况",
      ],
      mustSearch: ["公司名称", "客户区域", "负责人", "联系人名称"],
      loading: true,
      page: 1,
      pageSize: 20,
      selection_num: 0,
      total: 0,
      addr: true,
      searchKey: 1,
      type: "1",
      status_group: [],
      clue_list: [],
      current_operate_clue_id: "",
      clue_ids: "",
      assign_clue_ids: "",
      clue_status: "",
      cusStatusGroupOptions: [],
      chargeUserOptions: [],
      is_create_plan: false,
      contact_id: "",
      want_create_time: "",
      plan_desc: "",
      contact_options: [],
      plan_type: "",
      plan_type_name: "",
      plan_type_options: [
        {
          type: "0",
          name: this.$t('ContactEdit.telephone'),
          icon: "icon-dianhua",
        },
        {
          type: "1",
          name: this.$t('clueList.email'),
          icon: "icon-duanxin",
        },
        {
          type: "2",
          name: this.$t('ContactEdit.visit'),
          icon: "icon-xinxiang",
        },
        {
          type: "3",
          name: this.$t('ContactEdit.meeting'),
          icon: "icon-qita",
        },
        {
          type: "4",
          name: this.$t('ContactInfo.newTask'),
          icon: "icon-chuangjianrenwu",
        },
      ],
      batch_operation_type: "",
      batch_operation_options: [
        {
          value: "status",
          name: this.$t('clueList.changeStatus'),
          type_arr: ["", "1"],
        },
        {
          value: "assign",
          name: this.$t('clueList.newAssign'),
          type_arr: ["", "1"],
        },
        {
          value: "give_up",
          name: this.$t('clueList.giveUp'),
          type_arr: ["", "1"],
        },
        {
          value: "del",
          name: this.$t('delete'),
          type_arr: ["", "1", "2"],
        },
        {
          value: "recovery",
          name: this.$t('clueList.restore'),
          type_arr: ["", "3"],
        },
        {
          value: "active",
          name: this.$t('clueList.active'),
          type_arr: ["", "2"],
        },
      ],
      is_change_status: false,
      is_change_assign: false,
      checkedUserArr: [],
      checkAllArr: [],
      isIndeterminateArr: [],
      statusOp: [
        {
          val: "",
          name: this.$t('clueList.all'),
        },
        {
          val: "1",
          name: this.$t('clueList.unfinished'),
        },
        {
          val: "2",
          name: this.$t('clueList.finished'),
        },
        {
          val: "3",
          name: this.$t('clueList.recycle'),
        },
      ],
      activeIndex: 1,
      col: [
        {
          label: this.$t('clueList.clueName'),
          prop: "clue_name",
        },
        {
          label: this.$t('clueList.status'),
          prop: "clue_status",
        },
        {
          label: this.$t('clueList.event'),
          prop: "recent_plan",
        },
        {
          label: this.$t('clueList.createTime'),
          prop: "create_time",
        },
        {
          label: this.$t('clueList.leader'),
          prop: "charge_user_names",
        },
      ],
      dropCol: [
        {
          label: this.$t('clueList.clueName'),
          prop: "clue_name",
        },
        {
          label: this.$t('clueList.status'),
          prop: "clue_status",
        },
        {
          label: this.$t('clueList.event'),
          prop: "recent_plan",
        },
        {
          label: this.$t('clueList.createTime'),
          prop: "create_time",
        },
        {
          label: this.$t('clueList.leader'),
          prop: "charge_user_names",
        },
      ],
      allShowColumn: [
        {
          label: this.$t('clueList.clueName'),
          prop: "clue_name",
        },
        {
          label: this.$t('clueList.status'),
          prop: "clue_status",
        },
        {
          label: this.$t('clueList.event'),
          prop: "recent_plan",
        },
        {
          label: this.$t('clueList.createTime'),
          prop: "create_time",
        },
        {
          label: this.$t('clueList.leader'),
          prop: "charge_user_names",
        },
      ],
      checkListField: [],
      allDisColumn: [],
      widthObj: {
        clue_name: "180",
        clue_status: "180",
        recent_plan: "200",
        create_time: "200",
        charge_user_names: "200",
      },
      checked_clue_list: [],
      isMultiple: false, // 是否批量
      canActiveArr: [],
      userDialogTit: "",
    };
  },
  computed: {
    // 计算表格宽度
    tableWidth: function () {
      let wArr = [];
      this.col.forEach((item) => {
        wArr.push(this.widthObj[item.prop]);
      });
      let temW = wArr.reduce(function (prev, cur) {
        return prev * 1 + cur * 1;
      });
      // 多选框以及操作栏的宽度155
      if (temW + 155 > 1400) {
        return "100%";
      } else {
        return temW + 155 + "px";
      }
    },
  },
  methods: {
    reset() {
      this.key = "";
      this.clue_status = "";
      this.page = 1;
      this.pageSize = 20;
      this.getList(0);
    },
    getData(data) {
      let ajax = {
        ...data.searchData,
      };
      this.searchData = ajax;
      if (data.type === 1) {
        this.getList(2, this.searchData);
      }
    },
    setUp() {
      this.allDisColumn = [];
      this.checkListField = [];
      var hash = {};
      this.allShowColumn.forEach((item, index) => {
        let obj = {};
        obj["label"] = item.label;
        obj["value"] = index;
        hash[item.prop] = obj;
      });
      this.col.forEach((item) => {
        this.checkListField.push(hash[item.prop]["value"]);
        this.allDisColumn.push(hash[item.prop]);
      });
    },
    //取消编辑字段
    cancelField() {
      this.checkListField = [];
      this.allDisColumn = [];
      document.getElementById("clue-list").click();
    },
    //完成编辑字段
    confirmField() {
      if (this.checkListField.length == 0) {
        this.$message(
          toMessage("error", this.$t('clueList.error'))
        );
        return;
      }
      this.col = [];
      this.dropCol = [];
      let listCol = [];
      this.checkListField.sort();
      this.checkListField.forEach((item) => {
        listCol.push(
          JSON.parse(JSON.stringify(this.allShowColumn[item]["prop"]))
        );
        this.col.push(JSON.parse(JSON.stringify(this.allShowColumn[item])));
        this.dropCol.push(JSON.parse(JSON.stringify(this.allShowColumn[item])));
      });
      this.checkListField = [];
      this.allDisColumn = [];
      if (this.is_show == 1) {
        this.is_show = 0;
      } else {
        this.is_show = 1;
      }
      this.visible = false;
      let obj = {};
      obj["name"] = "CrmContact_clueList";
      obj["option"] = listCol;
      savePageOption(obj, "/");
      document.getElementById("clue-list").click();
    },
    //改变选项
    changeCheck(val) {
      this.allDisColumn = [];
      val.forEach((item) => {
        let obj = {};
        obj["label"] = this.allShowColumn[item].label;
        obj["value"] = item;
        this.allDisColumn.push(obj);
      });
    },
    //删除字段
    delField(index) {
      this.checkListField.splice(index, 1);
      this.allDisColumn.splice(index, 1);
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        handle: ".can-move",
        onEnd: (evt) => {
          const oldItem = this.dropCol[evt.oldIndex - 2];
          this.dropCol.splice(evt.oldIndex - 2, 1);
          this.dropCol.splice(
            evt.newIndex - 2,
            0,
            JSON.parse(JSON.stringify(oldItem))
          );
          let listCol = [];
          this.dropCol.forEach((item) => {
            listCol.push(item.prop);
          });
          let obj = {};
          obj["name"] = "CrmContact_clueList";
          obj["option"] = listCol;
          savePageOption(obj, "{$webPath}");
        },
        // 多选框禁止拖动
        onMove: (evt) => {
          if (evt.related.className.indexOf("el-table-column--selection") > 0) {
            return false;
          }
        },
      });
    },
    // 判断线索进行到哪一步
    getStatusIndex(id) {
      return this.status_group[id]["order_index"] + 1;
      // let index = 0
      // let temStatus = Object.values(this.status_group)
      // for (let i = 0; i < temStatus.length; i++) {
      //     if(temStatus[i].id === id){
      //         index = i
      //         break;
      //     }
      // }
      // return index+1
    },
    // 根据计划的类型获取对应的icon
    getIconByType(type) {
      let icon = "";
      this.plan_type_options.forEach((item) => {
        if (item.type == type) {
          icon = item.icon;
        }
      });
      return icon;
    },
    // 标签点击事件
    changeStatus(tab, index) {
      this.type = tab;
      this.sIndex = index;
      this.activeIndex = index;
      this.getList();
    },
    // 线索列表选择项发生改变事件
    clue_selection_change(selection) {
      this.selection_num = selection.length;
      let clue_id_list = selection.map(function (clue) {
        return clue.id;
      });
      this.checked_clue_list = selection;
      this.clue_ids = clue_id_list;
    },
    // 获取线索列表
    getList(operation, ajaxData = {}) {
      let ajax = {};
      this.page = operation === 2 ? 1 : this.page;
      if (operation == 1) {
        ajax = {
          key: this.key,
          type: this.type,
          page: this.page,
          pageSize: this.pageSize,
          ...ajaxData,
        };
      } else {
        ajax = {
          key: this.key,
          type: this.type,
          page: this.page,
          pageSize: this.pageSize,
        };
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getClueList", ajax)
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          if (res.data.habit_page) {
            let listCol = {};
            this.allShowColumn.forEach((item) => {
              listCol[item.prop] = item;
            });
            this.col = [];
            this.dropCol = [];
            res.data.habit_page.forEach((item) => {
              this.col.push(listCol[item]);
              this.dropCol.push(listCol[item]);
              //this.col = JSON.parse(JSON.stringify(res.data.habit_page))
              //this.dropCol = JSON.parse(JSON.stringify(res.data.habit_page))
            });
          }
          this.canActiveArr = [];
          this.total = res.data.total;
          res.data.clue_list.forEach((item) => {
            if (item.clue_status == -1) {
              this.canActiveArr.push(item.id);
            }
          });
          this.clue_list = res.data.clue_list;
          this.clue_ids = [];
          this.batch_operation_type = "";
          this.status_group = res.data.status_group;
        });
    },
    // 获取用户自定义线索状态组
    getCusStatusGroup() {
      this.axios
        .post("/home/CrmContact/getCusStatusGroup")
        .then((res) => res.data)
        .then((res) => {
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.cusStatusGroupOptions = res.data;
        });
    },
    // 获取负责人
    getChargeUserList() {
      this.axios
        .post("/home/CrmContact/getChargeUserList")
        .then((res) => res.data)
        .then((res) => {
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          res.data.class_list = res.data.class_list.filter((item) => {
            return item.children !== undefined;
          });
          this.chargeUserOptions = res.data.class_list;
          res.data.class_list.forEach((item) => {
            if (item.children !== undefined) {
              this.checkAllArr.push(false);
              this.isIndeterminateArr.push(false);
              this.checkedUserArr.push([]);
            }
          });
        });
    },
    addTab(option = "Index", action = "welCome", name = "首页", param) {
      let url = "/" + option + "/" + action;
      console.log(name);
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      this.$router.push(url);
    },
    // 新增线索点击
    // is_view 0新增,编辑 1查看
    add_clue() {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>新增线索</tags:lang>"
      );
    },
    // 操作列显示
    show_operation(item) {
      this.current_operate_clue_id = item.id;
      this.contact_options = item.contact_list;
      this.contact_id = item.contact_list ? item.contact_list[0].id : "";
    },
    // 查看详情
    view_details(id) {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>线索详情</tags:lang>",
        {
          id: id,
          is_view: 1,
        }
      );
    },
    view_del_details(id) {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>线索详情</tags:lang>",
        {
          id: id,
          is_view: 1,
          is_delete: 1,
        }
      );
    },
    // 编辑线索
    edit() {
      let id = this.current_operate_clue_id;
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>线索编辑</tags:lang>",
        {
          id: id,
          is_view: 0,
        }
      );
    },
    // 添加跟进人
    addClueCharge() {
      let charge_user_ids = [];
      this.checkedUserArr.forEach((fItem) => {
        fItem.forEach((sItem) => {
          charge_user_ids.push(sItem);
        });
      });
      let ids = "";
      if (this.isMultiple) {
        ids = this.clue_ids;
        if (this.clue_ids.length == 0) {
          this.$message(
            toMessage("error", this.$t('clueList.error1'))
          );
          return;
        }
      } else {
        ids = [this.assign_clue_ids];
      }
      let ajaxData = {
        ids: ids,
        operation: 1,
        source_type: this.isMultiple ? 1 : 0,
        charge_user_ids: charge_user_ids,
      };
      if (!this.isMultiple) {
        // 单个指派
        this.loading = true;
        this.axios
          .post("/home/CrmContact/changeCharge", ajaxData)
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status !== 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.$message(toMessage());
            this.getList();
            this.is_change_assign = false;
          });
      } else {
        this.clueOperationAjax(ajaxData);
      }
    },
    emptyUser() {
      this.checkedUserArr = [];
      this.checkAllArr = [];
      this.isIndeterminateArr = [];
      this.chargeUserOptions.forEach((item) => {
        if (item.children) {
          this.checkAllArr.push(false);
          this.isIndeterminateArr.push(false);
          this.checkedUserArr.push([]);
        }
      });
    },
    // 单个指派
    assign(id, user_ids) {
      this.assign_clue_ids = id;
      this.isMultiple = false;
      this.is_change_assign = true;
      this.userDialogTit = this.$t('clueList.editLeader');
      this.emptyUser();

      let charge_user_ids = user_ids;
      let userArr = [];
      if (charge_user_ids.indexOf(",")) {
        userArr = charge_user_ids.split(",");
      } else {
        userArr = charge_user_ids * 1;
      }
      // 更改跟进人中checkbox 选择的样式
      this.chargeUserOptions.forEach((item) => {
        item.childrenL = 0;
        item.children.forEach(() => {
          item.childrenL += 1;
        });
      });
      this.chargeUserOptions.forEach((item, index) => {
        let checkL = 0;
        item.children.forEach((sItem) => {
          userArr.forEach((uItem) => {
            if (sItem.value == uItem) {
              checkL += 1;
              this.checkedUserArr[index].push(uItem * 1);
            }
          });
        });
        let isCheckAllflag = checkL === item.childrenL;
        this.checkAllArr.splice(index, 1, isCheckAllflag);
        let isIndeterminateArr = checkL > 0 && checkL < item.childrenL;
        this.isIndeterminateArr.splice(index, 1, isIndeterminateArr);
      });
    },
    // 添加计划
    create_plan(item) {
      this.plan_type = item.type;
      this.plan_type_name = item.name;
      // let id = this.current_operate_clue_id;
      this.is_create_plan = true;
    },
    // 创建任务
    create_task() {
      let id = this.current_operate_clue_id;
      this.addTab("CrmTask", "taskInfo", "<tags:lang>新建任务</tags:lang>", {
        source_id: id,
        source_type: 2,
      });
    },
    // 关闭添加计划对话框
    close_create_plan() {
      this.is_create_plan = false;
      this.plan_type = "";
      this.want_create_time = "";
      this.plan_desc = "";
    },
    // 保存添加计划的信息
    addPlan() {
      if (this.contact_id == "") {
        this.$message(
          toMessage("error", this.$t('clueList.error2'))
        );
        return;
      }
      if (this.plan_type == "") {
        this.$message(
          toMessage("error", this.$t('clueList.error3'))
        );
        return;
      }
      if (this.want_create_time == "") {
        this.$message(
          toMessage("error", this.$t('clueList.error4'))
        );
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/addPlan", {
          clue_id: this.current_operate_clue_id,
          plan_type: this.plan_type,
          want_create_time: this.want_create_time,
          plan_desc: this.plan_desc,
          contact_id: this.contact_id,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
          this.getList();
          this.is_create_plan = false;
          this.plan_type = "";
          this.want_create_time = "";
          this.plan_desc = "";
        });
    },
    // 转化
    conversion() {
      let id = this.current_operate_clue_id;
      this.addTab(
        "CrmContact",
        "contactInfo",
        "<tags:lang>客户</tags:lang>-<tags:lang>转换</tags:lang>",
        { source_id: id }
      );
    },
    // 单条线索放弃
    give_up() {
      this.$confirm(
        this.$t('clueList.text10'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: "warning",
        }
      )
        .then(() => {
          let id = this.current_operate_clue_id;
          let ajaxData = {
            ids: [id],
            operation: 2,
            source_type: 0,
          };
          this.clueOperationAjax(ajaxData);
        })
        .catch(() => {});
    },
    // 单条线索删除
    del() {
      this.$confirm(
        this.$t('clueList.text8'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: "warning",
        }
      )
        .then(() => {
          let id = this.current_operate_clue_id;
          let ajaxData = {
            ids: [id],
            operation: 3,
            source_type: 0,
          };
          this.clueOperationAjax(ajaxData);
        })
        .catch(() => {});
    },
    // 恢复
    recovery() {
      this.$confirm(
        this.$t('clueList.text9'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: "warning",
        }
      )
        .then(() => {
          let id = this.current_operate_clue_id;
          let ajaxData = {
            ids: [id],
            operation: 4,
            source_type: 0,
          };
          this.clueOperationAjax(ajaxData);
        })
        .catch(() => {});
    },
    // 激活
    activation() {
      let id = this.current_operate_clue_id;
      let ajaxData = {
        ids: [id],
        operation: 5,
        source_type: 0,
      };
      this.clueOperationAjax(ajaxData);
    },
    // 单条改变线索状态
    changeClueStatus(clue_status) {
      let ajaxData = {
        ids: [this.current_operate_clue_id],
        operation: 0,
        source_type: 0,
        status: clue_status,
      };
      this.clueOperationAjax(ajaxData);
    },
    // 不是删除状态
    not_delete_status(value) {
      if (!value.is_delete) {
        return true;
      } else {
        return false;
      }
    },
    // 是删除状态
    is_delete_status(value) {
      if (value.is_delete) {
        return true;
      } else {
        return false;
      }
    },
    // 没有删除并且是未完成状态
    is_complete_status(value) {
      if (!value.is_delete && value.clue_status > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 批量更改线索状态
    batchChangeClueStatus() {
      let ajaxData = {
        operation: 0,
        source_type: 1,
        status: this.clue_status,
      };
      console.log(this.clue_ids);
      if (this.isMultiple) {
        ajaxData.ids = this.clue_ids;
      } else {
        ajaxData.ids = this.assign_clue_ids;
      }
      console.log(ajaxData);

      this.clueOperationAjax(ajaxData);
    },

    // 指派任务弹窗多选框的全选
    handleCheckAllChange(val, index) {
      let allId = [];
      this.chargeUserOptions[index].children.forEach((item) => {
        allId.push(item.value);
      });
      if (val) {
        this.checkedUserArr.splice(index, 1, allId);
      } else {
        this.checkedUserArr.splice(index, 1, []);
      }
      this.isIndeterminateArr.splice(index, 1, false);
    },
    // 指派任务弹窗多选框的单选
    handleCheckedChange(value, index) {
      let checkedCount = value.length;
      let isCheckAllflag =
        checkedCount === this.chargeUserOptions[index].children.length;
      this.checkAllArr.splice(index, 1, isCheckAllflag);
      let isIndeterminateflag =
        checkedCount > 0 &&
        checkedCount < this.chargeUserOptions[index].children.length;
      this.isIndeterminateArr.splice(index, 1, isIndeterminateflag);
    },
    application() {
      if (this.clue_ids.length == 0) {
        this.$message(toMessage("error", this.$t('clueList.error5')));
        this.batch_operation_type = "";
        return;
      }
      if (this.batch_operation_type == "") {
        return;
      }
      let operationObj = {
        status: 0,
        assign: 1,
        give_up: 2,
        del: 3,
        recovery: 4,
        active: 5,
      };
      let ajaxData = {
        ids: this.clue_ids,
        operation: operationObj[this.batch_operation_type],
        source_type: 1,
      };
      if (ajaxData.operation === 0) {
        ajaxData.status = this.clue_status;
      }
      if (this.batch_operation_type == "status") {
        // 更改状态
        if (!this.checked_clue_list.every(this.is_complete_status)) {
          this.$message(
            toMessage("error", this.$t('clueList.error6'))
          );
          return;
        }
        this.is_change_status = true;
        this.clue_status = "";
        this.isMultiple = true;
        return;
      }
      if (this.batch_operation_type == "assign") {
        // 添加指派
        if (!this.checked_clue_list.every(this.is_complete_status)) {
          this.$message(
            toMessage("error", this.$t('clueList.error7'))
          );
          return;
        }
        this.userDialogTit = this.$t('clueList.newAssign');
        this.emptyUser();
        this.isMultiple = true;
        this.is_change_assign = true;
        return;
      }
      if (this.batch_operation_type == "give_up") {
        // 批量放弃
        if (!this.checked_clue_list.every(this.is_complete_status)) {
          this.$message(
            toMessage("error", this.$t('clueList.error8'))
          );
          return;
        }
        this.$confirm(
          this.$t('clueList.text11'),
          this.$t('tips'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: "warning",
          }
        )
          .then(() => {
            this.clueOperationAjax(ajaxData);
          })
          .catch(() => {});
        return;
      }
      if (this.batch_operation_type == "del") {
        // 批量删除
        if (this.checked_clue_list.some(this.is_delete_status)) {
          this.$message(
            toMessage("error", this.$t('clueList.error9'))
          );
          return;
        }
        this.$confirm(
          this.$t('clueList.text12'),
          this.$t('tips'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: "warning",
          }
        )
          .then(() => {
            this.clueOperationAjax(ajaxData);
          })
          .catch(() => {});
        return;
      }
      if (this.batch_operation_type == "recovery") {
        // 恢复
        if (this.checked_clue_list.some(this.not_delete_status)) {
          this.$message(
            toMessage("error", this.$t('clueList.error10'))
          );
          return;
        }
      }
      if (this.batch_operation_type == "active") {
        // 激活
        let isFalseFlag = false;
        for (let cIndex = 0; cIndex < this.clue_ids.length; cIndex++) {
          const element = this.clue_ids[cIndex];
          if (this.canActiveArr.indexOf(element) < 0) {
            isFalseFlag = true;
            break;
          }
        }
        if (isFalseFlag) {
          this.$message(
            toMessage(
              "error",
              this.$t('clueList.error11')
            )
          );
          return;
        }
        this.$confirm(
          this.$t('clueList.text13'),
          this.$t('tips'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: "warning",
          }
        )
          .then(() => {
            this.clueOperationAjax(ajaxData);
          })
          .catch(() => {});

        return;
      }

      this.clueOperationAjax(ajaxData);
    },
    // 批量和单个操作接口
    clueOperationAjax(ajaxData) {
      this.loading = true;
      this.axios
        .post("/home/CrmContact/clueOperation", ajaxData)
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
          this.getList();
          this.is_change_assign = false;
          this.is_change_status = false;
          this.batch_operation_type = "";
        });
    },
  },
  created: function () {
    // let tabData = this.$route.params;
    // if (tabData.isTab) {
    //   this.showTab();
    // } else {
    //   this.getList();
    //   this.getCusStatusGroup();
    //   this.getChargeUserList();
    // }
    this.getList();
    this.getCusStatusGroup();
    this.getChargeUserList();
  },
  mounted() {
    this.columnDrop();
    const self = this;
    this.timer = setInterval(() => {
      try {
        if (self.addr && this.cacheAddr) {
          self.address = this.cacheAddr;
          self.addr = false;
          self.searchKey++;
        }
        if (!self.addr && this.cacheAddr) {
          clearInterval(this.timer);
        }
      } catch (e) {
        console.log("");
      }
    }, 1000);
  },
  beforeDestory() {
    clearInterval(this.timer);
  },
  beforeCreate() {
    this.axios.get("/public/addr.js").then((res) => {
      this.cacheAddr = JSON.parse(res.data.slice(16, -1));
    });
  },
};
</script>

<style scoped>
#clue-list {
  padding: 28px !important;
  height: 100%;
  overflow: auto;
}
.container {
  min-width: 1280px;
  max-width: 1500px;
  margin-bottom: 110px !important;
}

.top_box {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.tabs_box {
  margin: 0 20px;
  flex: auto;
}

.operation_item {
  width: 100%;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.space_between {
  justify-content: space-between;
}

.ml8 {
  margin-left: 8px;
}

.mr3 {
  margin-right: 3px;
}

.status_box {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.status_box .title {
  margin-right: 10px;
}

.plan_info_box {
  display: flex;
  flex-direction: column;
}

.plan_info_item {
  margin-bottom: 20px;
}

.plan_info_item .tit {
  margin-right: 20px;
  width: 100px;
  text-align: right;
}

.w500 {
  width: 500px;
}

.flex {
  display: flex;
  align-items: center;
}

.active-tab {
  color: #ff7315;
}

.status-box {
  color: #858585;
  font-size: 16px;
  margin-left: 20px;
}

.status-box span {
  margin-right: 39px;
  cursor: pointer;
}

.add-btn {
  color: #666666;
  font-size: 14px;
}

.el-pagination {
  margin: 0 auto;
}

.clue-status_box {
  display: flex;
  align-items: center;
}

.clue-status_box span {
  width: 34px;
  height: 10px;
  border: 1px solid rgba(10, 132, 255, 0.1);
  background: #0a84ff;
}

.clue-status_box span:nth-child(1) {
  border-radius: 35px 0px 0px 35px;
  opacity: 0.4;
  border-right: none;
}

.clue-status_box span:last-child {
  border-radius: 0px 100px 100px 0px;
}

.clue-status_box span:nth-child(2) {
  opacity: 0.6;
}

.clue-status_box span:nth-child(3) {
  opacity: 0.8;
}

.clue-status_box span:nth-child(2),
.clue-status_box span:nth-child(3) {
  border-right: none;
}

.blue-status span {
  background: #0a84ff;
}

.red-status span {
  background: #ff5763;
}

.green-status span {
  background: #0fd153;
}

.no-color {
  background: #fff !important;
}

.w220 {
  width: 220px;
}

.el-table th:last-child {
  cursor: pointer;
}

/* 筛选popover */
.handel-popover {
  display: flex;
  padding: 30px;
}

.popover-left {
  width: 258px;
  margin-right: 100px;
}

.popover-left-top {
  font-weight: 600;
  font-size: 16px;
}

.popover-checkbox-group {
  margin-top: 7px;
}

.popover-checkbox-group {
  border: 1px solid rgba(28, 28, 30, 0.1);
  border-radius: 3px;
  padding: 16px;
}

.popover-checkbox-group >>> .el-checkbox {
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popover-checkbox-group >>> .el-checkbox:last-child {
  margin-bottom: 0;
}

.popover-checkbox-group >>> .el-checkbox__label {
  padding-left: 0;
}

.popover-checkbox-group >>> .el-checkbox .el-checkbox__label {
  color: #1c1c1e;
  font-size: 16px;
}

.popover-checkbox-group
  >>> .el-checkbox__input.is-checked
  + .el-checkbox__label {
  color: #1c1c1e;
  font-size: 16px;
}

.popover-checkbox-group >>> .el-checkbox__inner {
  width: 15px;
  height: 15px;
}

.popover-checkbox-group >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff7315;
  border-color: #ff7315;
}

.popover-right {
  width: 258px;
}

.has-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  color: #1c1c1e;
  align-items: center;
  height: 19px;
}

.has-item:last-child {
  margin-bottom: 0px;
}

.popover-handel-box {
  margin-top: 5px;
  margin-bottom: 20px;
}

.popover-handel-box >>> .el-button {
  margin-right: 75px;
}

.delete_color {
  text-decoration: line-through;
}

.handel-bottom {
  position: fixed;
  bottom: 0;
  margin-left: -28px;
  width: 100%;
  z-index: 3;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  height: 84px;
}

.handel-inner_box {
  min-width: 1280px;
  max-width: 1700px;
  display: flex;
  align-items: center;
  background: #fff;
  padding-left: 56px;
}

.el-pagination {
  margin: 0 auto;
}

.select-span {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}

.mr24 {
  margin-right: 24px;
}

.change-status-dia >>> .el-dialog__body {
  min-height: 100px;
  display: flex;
  justify-content: center;
}

/* 表格手型 */
.el-table th:not(:first-child) {
  cursor: move;
}

.el-table th:last-child {
  cursor: pointer;
}

.plan_item_box {
  color: #333333;
  opacity: 0.6;
  width: 190px;
}

.ml50 {
  margin-left: 50px;
}

.user-dialog {
  padding-left: 32px;
  padding-right: 32px;
}

.group_name {
  color: #1c1c1e;
  font-weight: 600;
  font-size: 16px;
}

.department-tit {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
  position: relative;
  margin-bottom: 16px;
}

.department-tit >>> .el-checkbox__label {
  padding-left: 0;
  margin-right: 6px;
}

.status_item {
  line-height: 1.2;
  margin-bottom: 12px;
}
</style>